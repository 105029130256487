// Common
import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// Services
import { ProjectsService } from '@modules/tasks/services/projects.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

// Types
import { Project } from '@modules/tasks/types/project';
import { Task } from '@modules/tasks/types/task';
import { DragData, dragDataTypeAllowed, DragDataTypes } from '@modules/drag-n-drop/types/drag-data';

// RX
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// Components
import { BaseStitchComponent } from '@modules/common/components/base-stitch/base-stitch.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['../../../common/components/base-stitch/base-stitch.component.less', './project.component.less']
})
export class ProjectComponent extends BaseStitchComponent<Project> {
  @Output() openTask = new EventEmitter<Task>();

  public itemsCount = 0;
  public form: UntypedFormGroup;

  moveDragDataTypes = [DragDataTypes.task, DragDataTypes.project];
  dragDataType = DragDataTypes.project;
  dndPredicate = (dragData: DragData): boolean =>
    this.item &&
    !(
      dragData.type === DragDataTypes.project &&
      dragData.data.length === 1 &&
      dragData.data[0]['id'] === this.item.id
    ) &&
    dragDataTypeAllowed(dragData.type)

  constructor (
    injector: Injector,
    protected projectsService: ProjectsService,
    private tasksService: TasksService,
  ) { super(injector, projectsService) }

  ngOnInit() {
    this.changed
      .pipe(
        tap(() => this.form = (this.item || new Project()).asFormGroup()),
        switchMap(() => this.form.valueChanges),
        debounceTime(200),
        switchMap(() => this.projectsService.update(Project.fromFormGroup(this.form), { emit: false })),
        takeUntil(this.alive)
      )
      .subscribe();

    super.ngOnInit();
  }

  handleMove(dragData: DragData) {
    const message = 'Successfully moved to ' + this.item.title;

    (() => {
      if (dragData.type === DragDataTypes.task) {
        return this.tasksService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { projectId: this.item.id },
          { message }
        );
      } else if (dragData.type === DragDataTypes.project) {
        return this.projectsService.bunchUpdate(
          { ids: this.getIds(dragData.data) },
          { parentId: this.item.id },
          { message }
        );
      }
    })()
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe()
  }

  handleComplete() {
    this.item.completed = !this.item.completed;
    this.projectsService.update(this.item)
      .pipe(
        take(1),
        takeUntil(this.alive)
      )
      .subscribe();
  }

  get project(): Project {
    return this.item;
  }
}
