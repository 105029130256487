// Common
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CLOSE_POPOVER } from '@modules/popover/types/close-popover.injection-token';

// Rx
import { Subject, ReplaySubject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stch-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrl: './date-time-picker.component.less'
})
export class DateTimePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dateControl: FormControl<Date>;
  @Input() timeControl: FormControl<Date>;
  @Input() disabled = false;
  @Input() appearance: 'outline' | 'ghost' | 'inline' = 'outline';

  public innerDateControl = new FormControl<Date>(null);
  public innerTimeControl = new FormControl<Date>(null);
  public opened = false;
  public closePopover = new Subject<void>();

  private dateControlChanged = new ReplaySubject();
  private timeControlChanged = new ReplaySubject();
  private alive = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.dateControlChanged
      .pipe(
        switchMap(() => this.dateControl.valueChanges),
        startWith(this.dateControl.value),
        takeUntil(this.alive)
      )
      .subscribe((value) => this.innerDateControl.setValue(value));

    this.timeControlChanged
      .pipe(
        switchMap(() => this.timeControl.valueChanges),
        startWith(this.timeControl.value),
        takeUntil(this.alive)
      )
      .subscribe((value) => this.innerTimeControl.setValue(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('dateControl' in changes) { this.dateControlChanged.next(); }
    if ('timeControl' in changes) { this.timeControlChanged.next(); }
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  handleSave() {
    this.dateControl.setValue(this.innerDateControl.value);
    this.timeControl.setValue(this.innerTimeControl.value);
    this.closePopover.next();
  }

  handleClear() {
    this.dateControl.setValue(null);
    this.dateControl.markAsPristine();
    this.timeControl.setValue(null);
    this.timeControl.markAsPristine();
  }
}
