// Common
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

// RX
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';

// Services
import { CommentsService } from '@modules/comments/services/comments.service';
import { SpacesService } from '@modules/settings/services/spaces.service';
import { AccountService } from '@modules/account/services/account.service';

// Types
import { Stitch } from '@modules/common/types/stitch';
import { Comment } from '@modules/comments/types/comment';
import { Account } from '@modules/account/types/account';
import { Space } from '@modules/settings/types/space';

@Component({
  selector: 'stch-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.less']
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() stitch: Stitch;
  @Input() placeholder: string;

  public account: Account;
  public comments: Comment[] = [];
  public space: Space;
  public page = 0;
  public perPage = 20;
  public pagesCount = 0;
  public count = 0;

  private alive = new Subject();
  private loadPage = new Subject<void>();

  constructor (
    private commentsService: CommentsService,
    private spacesService: SpacesService,
    private accountService: AccountService,
  ) { }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.loadPage
      .pipe(
        debounceTime(300),
        filter(() => !!this.stitch?.id),
        switchMap(() => this.commentsService.search(
          this.stitch.getStitchType(),
          this.stitch.id,
          { offset: this.page * this.perPage, limit: this.perPage }
        )),
        takeUntil(this.alive)
      )
      .subscribe(({ items, count }) => {
        this.pagesCount = Math.ceil(count / this.perPage);
        this.count = count;
        this.comments = [
          ...this.comments.slice(0, this.page * this.perPage),
          ...items,
          ...this.comments.slice((this.page + 1) * this.perPage, count),
        ].filter(i => !!i);
      });

    this.commentsService.getRefresh()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.refresh();
      })

    this.accountService.getAccount()
      .pipe(takeUntil(this.alive))
      .subscribe(account => {
        this.account = account;
      });

    this.spacesService.getCurrentSpace()
      .pipe(takeUntil(this.alive))
      .subscribe(space => {
        this.space = space;
      });

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('stitch' in changes) {
      this.refresh();
    }
  }

  ngOnDestroy() {
    this.loadPage.complete();
    this.alive.next();
    this.alive.complete();
  }

  /**
   * Actions
   */

  private refresh() {
    this.page = 0;
    this.count = 0;
    this.pagesCount = 0;
    this.comments = [];
    this.loadPage.next();
  }

  showMore() {
    this.page = Math.min(this.page + 1, this.pagesCount - 1);
    this.loadPage.next();
  }
}
