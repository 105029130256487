// Common
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LSBoolean, LocalStorageItem } from 'src/app/decorators/local-storage.decorator';
import { heightAnimation } from '@modules/common/animations/height.animation';

// RX
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

// Services
import { TagsService } from '@modules/tags/services/tags.service';
import { AdvancedSearchService } from '@modules/search/services/advanced-search.service';
import { SplitViewService } from '@modules/split-view/services/split-view.service';

// Types
import { Tag } from '@modules/tags/types/tag';
import { VirtualScrollDataSource } from '@modules/scroll/types/virtual-scroll-datasource';
import { TagFilters } from '@modules/tags/types/tag-filters';
import { ManageListState } from '@modules/tags/types/manage-list-state';
import { StateKey } from '@modules/settings/types/state-key';
import { SidebarSplitViewKey } from '@modules/common/types/sidebar-split-view-key';
import { Team } from '@modules/settings/types/team';

@Component({
  selector: 'app-sidebar-tags',
  templateUrl: './sidebar-tags.component.html',
  styleUrls: ['./sidebar-tags.component.less'],
  animations: [heightAnimation],
})
export class SidebarTagsComponent implements OnInit, OnDestroy {

  public count = 0;
  public quickFormPopoverClose = new Subject();
  public dataSource: VirtualScrollDataSource<Tag>;
  public stateKey = StateKey.sidebarTagsState;
  public minimized = false
  @LSBoolean({ lsKey: 'sidebar-tags.collapsed' }) public expanded: LocalStorageItem<boolean>;

  private filters = new BehaviorSubject<TagFilters>(null);
  private alive = new Subject<void>();

  @Input() sidebarSplitViewKey: SidebarSplitViewKey;
  @Input() team: Team;

  constructor (
    private tagsService: TagsService,
    private searchService: AdvancedSearchService,
    private splitViewService: SplitViewService
  ) {
    this.dataSource = new VirtualScrollDataSource(this.tagsService, this.filters);
  }

  /**
   * Lifecycle
   */

  ngOnInit() {
    this.dataSource.dataSizeChanged
      .pipe(takeUntil(this.alive))
      .subscribe(count => {
        this.count = count;
      });

    this.splitViewService.getMinimized(this.sidebarSplitViewKey)
      .pipe(takeUntil(this.alive))
      .subscribe(minimized => {
        this.minimized = minimized;
      });
  }

  ngOnDestroy() {
    this.quickFormPopoverClose.next();
    this.quickFormPopoverClose.complete();
  }

  /**
   * Actions
   */

  closePopovers() {
    this.quickFormPopoverClose.next();
  }

  setState(state: ManageListState) {
    this.filters.next(new TagFilters({
      ...TagFilters.fromManageListState(state),
      pinned: true,
      withUnread: true
    }));
  }

  handleClick(tag: Tag) {
    this.searchService.addKnowledgeItem(tag);
  }
}
