// Common
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ClickDirective } from './directives/click/click.directive';
import { RepeatDirective } from './directives/repeat/repeat.directive';
import { ResizeDirective } from './directives/resize/resize.directive';

// Array
import { FilterByPipe } from './pipes/array/filter-by.pipe';
import { FindByPipe } from './pipes/array/find-by.pipe';
import { FirstPipe } from './pipes/array/first.pipe';
import { HasOnePipe } from './pipes/array/has-one.pipe';
import { IncludesByPipe } from './pipes/array/includes-by.pipe';
import { JoinPipe } from './pipes/array/join.pipe';
import { LastPipe } from './pipes/array/last.pipe';
import { MapPipe } from './pipes/array/map.pipe';
import { SameArrayPipe } from './pipes/array/same-array.pipe';
import { SortByPipe } from './pipes/array/sort-by.pipe';
import { TestAllElementsPipe } from './pipes/array/test-all-elements.pipe';

// Object
import { AllPropertiesTruePipe } from './pipes/object/all-properties-true.pipe';
import { AssignPipe } from './pipes/object/assign.pipe';
import { SomeTruePipe } from './pipes/object/some-true.pipe';
import { AccessPropertyPipe } from './pipes/object/access-property.pipe';

// Stitch
import { StitchListTitleByTypePipe } from './pipes/stitch/stitch-list-title-by-type.pipe';
import { SelectedItemsDragDataPipe } from './pipes/stitch/selected-items-drag-data.pipe';

// File
import { FileColorByTypePipe } from './pipes/file/file-color-by-type.pipe';
import { FileMimeToExtPipe } from './pipes/file/file-mime-to-ext.pipe';
import { FileSizePipe } from './pipes/file/file-size.pipe';
import { FileTypeToIconPipe } from '@modules/utils/pipes/file/file-type-to-icon';

// String
import { CamelCaseToWordsPipe } from './pipes/string/camel-case-to-words.pipe';
import { FilterPipe } from './pipes/string/filter.pipe';
import { FirstWordPipe } from './pipes/string/first-word.pipe';
import { InitialsPipe } from './pipes/string/initials.pipe';
import { ReplacePipe } from './pipes/string/replace.pipe';
import { SafeHtmlPipe } from './pipes/stitch/safe-html.pipe';
import { SecondaryColorPipe } from './pipes/string/secondary-color.pipe';
import { TemplatePipe } from './pipes/string/template.pipe';

// Number
import { OrdinalPipe } from './pipes/number/ordinal.pipe';
import { BetweenPipe } from './pipes/number/between.pipe';

// DateTime
import { CompareToRelativeDatePipe } from './pipes/datetime/compare-to-relative-date.pipe';
import { DateTimeDisplayPipe } from './pipes/datetime/date-time-display.pipe';
import { DateToWordsPipe } from './pipes/datetime/date-to-words.pipe';
import { DifferentDayPipe } from './pipes/datetime/different-day.pipe';
import { FromRelativeDatePipe } from './pipes/datetime/from-relative-date.pipe';
import { InDateRangePipe } from './pipes/datetime/in-date-range.pipe';
import { IncludesNowPipe } from './pipes/datetime/includes-now.pipe';
import { MonthPipe } from './pipes/datetime/month.pipe';
import { PastPipe } from './pipes/datetime/past.pipe';
import { SameDayPipe } from './pipes/datetime/same-day.pipe';
import { SameMonthPipe } from './pipes/datetime/same-month.pipe';
import { SameWeekPipe } from './pipes/datetime/same-week.pipe';
import { SameYearPipe } from './pipes/datetime/same-year.pipe';
import { TimeAgoPipe } from './pipes/datetime/time-ago.pipe';
import { WeekendPipe } from './pipes/datetime/weekend.pipe';
import { YearPipe } from './pipes/datetime/year.pipe';

import { AppIconPipe } from './pipes/app-icon.pipe';
import { AppTitlePipe } from './pipes/app-title.pipe';
import { DateByDayIndexPipe } from './pipes/date-by-day-index.pipe';
import { IsNilPipe } from './pipes/is-nil.pipe';
import { LSValuePipe } from './pipes/ls-value.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { SameTimePipe } from './pipes/same-time.pipe';
import { StitchTypePipe } from './pipes/stitch/stitch-type.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    //// Directives

    ClickDirective,
    RepeatDirective,
    ResizeDirective,

    //// Pipes

    // Array
    FilterByPipe,
    FindByPipe,
    FirstPipe,
    HasOnePipe,
    JoinPipe,
    LastPipe,
    MapPipe,
    SameArrayPipe,
    SortByPipe,
    TestAllElementsPipe,

    // Object
    AccessPropertyPipe,
    AllPropertiesTruePipe,
    AssignPipe,
    SomeTruePipe,

    // Stitch
    SelectedItemsDragDataPipe,
    StitchListTitleByTypePipe,
    StitchTypePipe,

    // File
    FileColorByTypePipe,
    FileMimeToExtPipe,
    FileSizePipe,
    FileTypeToIconPipe,

    // String
    CamelCaseToWordsPipe,
    FilterPipe,
    FirstWordPipe,
    InitialsPipe,
    ReplacePipe,
    SafeHtmlPipe,
    SecondaryColorPipe,
    TemplatePipe,

    // Number
    OrdinalPipe,
    BetweenPipe,

    // DateTime
    CompareToRelativeDatePipe,
    DateTimeDisplayPipe,
    DateToWordsPipe,
    DifferentDayPipe,
    FromRelativeDatePipe,
    InDateRangePipe,
    MonthPipe,
    PastPipe,
    SameDayPipe,
    SameMonthPipe,
    SameWeekPipe,
    SameYearPipe,
    TimeAgoPipe,
    WeekendPipe,
    YearPipe,

    AppIconPipe,
    AppTitlePipe,
    DateByDayIndexPipe,
    IncludesByPipe,
    IncludesNowPipe,
    IsNilPipe,
    LSValuePipe,
    RelativeDatePipe,
    RelativeDatePipe,
    SameTimePipe,
  ],
  exports: [
    //// Directives

    ClickDirective,
    RepeatDirective,
    ResizeDirective,

    //// Pipes

    // Array
    FilterByPipe,
    FindByPipe,
    FirstPipe,
    HasOnePipe,
    JoinPipe,
    LastPipe,
    MapPipe,
    SameArrayPipe,
    SortByPipe,
    TestAllElementsPipe,

    // Object
    AccessPropertyPipe,
    AllPropertiesTruePipe,
    AssignPipe,
    SomeTruePipe,

    // Stitch
    SelectedItemsDragDataPipe,
    StitchListTitleByTypePipe,
    StitchTypePipe,

    // File
    FileColorByTypePipe,
    FileMimeToExtPipe,
    FileSizePipe,

    // String
    CamelCaseToWordsPipe,
    FilterPipe,
    FirstWordPipe,
    InitialsPipe,
    ReplacePipe,
    SafeHtmlPipe,
    SecondaryColorPipe,
    TemplatePipe,

    // Number
    OrdinalPipe,
    BetweenPipe,

    // DateTime
    CompareToRelativeDatePipe,
    DateTimeDisplayPipe,
    DateToWordsPipe,
    DifferentDayPipe,
    FromRelativeDatePipe,
    InDateRangePipe,
    MonthPipe,
    PastPipe,
    SameDayPipe,
    SameMonthPipe,
    SameWeekPipe,
    SameYearPipe,
    TimeAgoPipe,
    WeekendPipe,
    YearPipe,

    AppIconPipe,
    AppTitlePipe,
    DateByDayIndexPipe,
    FileTypeToIconPipe,
    IncludesByPipe,
    IncludesNowPipe,
    IsNilPipe,
    LSValuePipe,
    RelativeDatePipe,
    SameTimePipe,
  ],
  providers: [
    CamelCaseToWordsPipe,
    SameWeekPipe
  ]
})
export class UtilsModule { }

