// Common
import { NgModule } from '@angular/core';

// Modules
import { AlertModule } from '@modules/alert/alert.module';
import { CommonModule as AppCommonModule } from '../common/common.module';
import { CommonModule } from '@angular/common';
import { DragNDropModule } from '@modules/drag-n-drop/drag-n-drop.module';
import { FormControlsModule } from '@modules/form-controls/form-controls.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from '@modules/popover/popover.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollModule } from '@modules/scroll/scroll.module';
import { UtilsModule } from '@modules/utils/utils.module';

// Components
import { AccountComponent } from './components/general/account/account.component';
import { AdditionalComponent } from './components/notes/additional/additional.component';
import { AutoReplyComponent } from '@modules/settings/components/messages/auto-reply/auto-reply.component';
import { AutoReplyFormComponent } from '@modules/settings/components/messages/auto-reply/auto-reply-form/auto-reply-form.component';
import { AvatarFormComponent } from './components/avatar-form/avatar-form.component';
import { BillingComponent } from './components/general/billing/billing.component';
import { CalendarComponent as CalendarCalendarComponent } from './components/calendar/calendar/calendar.component';
import { ChangePasswordComponent } from './components/general/account/change-password/change-password.component';
import { ChargesComponent } from './components/general/charges/charges.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { DueDateComponent } from './components/tasking/due-date/due-date.component';
import { FilesComponent } from './components/files/files.component';
import { GeneralComponent as CalendarsGeneralComponent } from './components/calendar/general/general.component';
import { GeneralComponent as MessagesGeneralComponent } from './components/messages/general/general.component';
import { GeneralComponent as NotesGeneralComponent } from './components/notes/general/general.component';
import { GeneralComponent as TaskingGeneralComponent } from './components/tasking/general/general.component';
import { ImapComponent } from './components/messages/imap/imap.component';
import { ImportComponent } from './components/messages/import/import.component';
import { ImportFormComponent } from './components/messages/import/import-form/import-form.component';
import { NotificationsComponent } from './components/general/notifications/notifications.component';
import { PermissionsComponent } from './components/messages/permissions/permissions.component';
import { PermissionsListComponent } from './components/messages/permissions/list/list.component';
import { PlanCardComponent } from './components/general/plans/plan-card/plan-card.component';
import { PlansComponent } from './components/general/plans/plans.component';
import { QuickReplyComponent } from '@modules/settings/components/messages/quick-reply/quick-reply.component';
import { QuickReplyFormComponent } from '@modules/settings/components/messages/quick-reply/quick-reply-form/quick-reply-form.component';
import { RuleFormComponent } from './components/rules/rule-form/rule-form.component';
import { RulesComponent } from './components/rules/rules.component';
import { SecurityComponent } from './components/general/security/security.component';
import { SettingsSideBarComponent } from './components/side-bar/side-bar.component';
import { SharedComponent } from './components/common/shared/shared.component';
import { SignatureFormComponent } from '@modules/settings/components/messages/signatures/signature-form/signature-form.component';
import { SignaturesComponent } from '@modules/settings/components/messages/signatures/signatures.component';
import { SpaceFormComponent } from './components/spaces/spaces/space-form/space-form.component';
import { SpaceUsageLineComponent } from './components/space-usage-line/space-usage-line.component';
import { SpacesSpaceComponent } from './components/spaces/space/space.component';
import { SpacesSpaceParticipantsComponent } from './components/spaces/space/space-participants/space-participants.component';
import { SpacesSpaceRoleFormComponent } from './components/spaces/space/space-role-form/space-role-form.component';
import { SpacesSpaceRolesComponent } from './components/spaces/space/space-roles/space-roles.component';
import { SpacesSpacesComponent } from './components/spaces/spaces/spaces.component';
import { SpacesTeamComponent } from './components/spaces/team/team.component';
import { SpacesTeamsComponent } from './components/spaces/teams/teams.component';
import { StripeCardComponent } from './components/general/billing/stripe-form/stripe-form.component';
import { TeamFormComponent } from './components/spaces/teams/team-form/team-form.component';
import { TeamsTeamParticipantsComponent } from './components/spaces/team/team-participants/team-participants.component';
import { TeamsTeamRoleFormComponent } from './components/spaces/team/team-role-form/team-role-form.component';
import { TeamsTeamRolesComponent } from './components/spaces/team/team-roles/team-roles.component';
import { TemplateFormComponent } from '@modules/settings/components/messages/templates/template-form/template-form.component';
import { TemplatesComponent } from '@modules/settings/components/messages/templates/templates.component';
import { TimeDateComponent } from './components/general/time-date/time-date.component';

// Services
import { StateService } from './services/state.service';
import { StripeService } from './services/stripe.service';
import { SubscriptionPlansService } from './services/subscription-plans.service';
import { ChargesService } from './services/charges.service';
import { TeamsService } from './services/teams.service';

@NgModule({
  imports: [
    AlertModule,
    AppCommonModule,
    CommonModule,
    DragNDropModule,
    FormControlsModule,
    ImageCropperModule,
    MatIconModule,
    MatTooltipModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollModule,
    UtilsModule,
  ],
  declarations: [
    AccountComponent,
    AdditionalComponent,
    AutoReplyComponent,
    AutoReplyFormComponent,
    AvatarFormComponent,
    BillingComponent,
    CalendarCalendarComponent,
    CalendarsGeneralComponent,
    ChangePasswordComponent,
    ChargesComponent,
    ContactsComponent,
    DueDateComponent,
    FilesComponent,
    ImapComponent,
    ImportComponent,
    ImportFormComponent,
    MessagesGeneralComponent,
    NotesGeneralComponent,
    NotificationsComponent,
    PermissionsComponent,
    PermissionsListComponent,
    PlanCardComponent,
    PlansComponent,
    QuickReplyComponent,
    QuickReplyFormComponent,
    RuleFormComponent,
    RulesComponent,
    SecurityComponent,
    SettingsSideBarComponent,
    SharedComponent,
    SignatureFormComponent,
    SignaturesComponent,
    SpaceFormComponent,
    SpaceUsageLineComponent,
    SpacesSpaceComponent,
    SpacesSpaceParticipantsComponent,
    SpacesSpaceRoleFormComponent,
    SpacesSpaceRolesComponent,
    SpacesSpacesComponent,
    SpacesTeamComponent,
    SpacesTeamsComponent,
    StripeCardComponent,
    TaskingGeneralComponent,
    TeamFormComponent,
    TeamsTeamParticipantsComponent,
    TeamsTeamRoleFormComponent,
    TeamsTeamRolesComponent,
    TemplateFormComponent,
    TemplatesComponent,
    TimeDateComponent,
  ],
  exports: [
    AvatarFormComponent,
    SettingsSideBarComponent,
  ],
  providers: [
    StateService,
    ChargesService,
    StripeService,
    SubscriptionPlansService,
    TeamsService,
  ]
})
export class SettingsModule { }
